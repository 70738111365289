<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">
        Minimums Garantis Pepsia
        <v-btn
          @click.native="isOpenCreateDialog = !isOpenCreateDialog"
          color="primary"
          icon
          fab
          outlined
          elevation="4"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
        </v-btn>
      </p>
    </div>

    <v-dialog v-model="isOpenCreateDialog" width="50vw">
      <create-meta-modal
        @openDialogFunction="ocCreateDialog"
        :openDialogIsOpen="isOpenCreateDialog"
        :departmentId="departmentId"
        :varGroup="this.varGroup"
        onlyField="var_value_float"
        varNameType="month_number"
      ></create-meta-modal>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
    >
      <template v-slot:item.site_name="props">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            {{ props.item.site_name }}
          </template>
          <span>
            {{ props.item.site_domain }}
          </span>
        </v-tooltip>
      </template>

      <template v-for="field in months" v-slot:[`item.${field.value}`]="props">
        <v-edit-dialog
          :return-value.sync="props.item[field.value]"
          @save="save(props.item, field.value)"
          @cancel="cancel"
          @open="open"
          @close="close"
        >
          {{ props.item[field.value] }}
          <template v-slot:input>
            <v-text-field
              v-model="props.item[field.value]"
              label="Edit"
              single-line
              counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { monthsFrArray } from "@/utils/dates";
import CreateMetaModal from "@/components/common/modals/CreateMetaModal";
import axios from "@axios";
import {
  mdiDelete,
  mdiPlus,
  mdiMagnify,
  mdiStateMachine,
  mdiTextBoxMultipleOutline,
} from "@mdi/js";

export default {
  components: { CreateMetaModal },
  data() {
    return {
      departmentId: 0, // 2 => Programmatique,
      varGroup: "minimale_garantie_pepsia",
      sites: [],
      selectedSite: null,
      selectedMonth: null,
      inputValueFloat: null,
      months: [],
      snack: false,
      snackColor: "",
      snackText: "",
      pagination: {},
      dialogCreate: false,
      initialHeaderItem: {
        text: "Site",
        align: "start",
        sortable: false,
        value: "site_name",
      },
      headers: [],
      items: [],
      item: null,
      loading: false,
      icons: {
        mdiStateMachine,
        mdiPlus,
        mdiDelete,
        mdiTextBoxMultipleOutline,
        mdiMagnify,
      },
      isOpenCreateDialog: false,
    };
  },
  async created() {
    console.log("created");

    const departmentList = await this.$store.dispatch(
      "common/getDepartmentList"
    );
    const prog_dept = departmentList.find(
      (department = department.name === "Programmatique")
    );

    this.departmentId = prog_dept?.id ?? 0;

    // init table headers
    let months = [];
    let headers = [this.initialHeaderItem];
    for (const [i, value] of monthsFrArray.entries()) {
      let monthNb = i + 1;
      monthNb = monthNb < 10 ? `0${monthNb}` : `${monthNb}`;
      months.push({
        text: value,
        value: monthNb,
      });
      headers.push({
        text: value,
        align: "start",
        sortable: false,
        value: monthNb,
      });
    }
    this.months = months;
    this.headers = headers;

    this.updateData();
  },
  methods: {
    ocCreateDialog(value, response = false) {
      console.log(`ocCreateDialog(${value})`);
      this.isOpenCreateDialog = value;
      console.log(response);
      if (response) {
        if (response.status == 200) {
          this.updateData();
        } else {
          console.log("ERROR");
        }
      }
    },
    async save(item, month) {
      const varValueFloatOrNUll = item[month] == "" ? null : item[month];
      const params = {
        department_id: this.departmentId,
        var_group: this.varGroup,
        site_id: parseInt(item.site_id),
        var_name: month,
        var_value_float: varValueFloatOrNUll,
      };
      console.log("save params", params);
      const response = await axios.put("/metas/save", params);
      console.log("save response", response);
      if (response.status === 200) {
        console.log("Updated");
        this.snackColor = "success";
        this.snackText = `Saved`;
      } else {
        this.snackColor = "error";
        this.snackText = "An error occurred";
      }
      this.snack = true;
      this.updateData();
    },
    cancel() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    open() {
      // this.snack = true;
      // this.snackColor = "info";
      // this.snackText = "Dialog opened";
    },
    close() {
      console.log("Dialog closed");
    },
    async updateData() {
      console.log("loading data");
      this.loading = true;
      const queryParams = {
        department_id: this.departmentId,
        var_group: this.varGroup,
      };
      const {
        data: { items: responseItems },
      } = await axios.get("/metas/list-by-group-site", {
        params: queryParams,
      });
      console.log(responseItems);

      let siteById = {};
      for (const x of responseItems) {
        if (!(x.site_id in siteById)) {
          siteById[x.site_id] = {
            name: x.site_name,
            domain: x.site_domain,
          };
        }
      }
      console.log("siteById", siteById);

      const dbValuesBySite = this.formatMGArray(responseItems);
      console.log("dbValuesBySite", dbValuesBySite);

      const items = [];
      for (const [site_id, site_infos] of Object.entries(siteById)) {
        const obj = {
          site_id: site_id,
          site_name: site_infos.name,
          site_domain: site_infos.domain,
        };
        for (let j = 1; j <= 12; j++) {
          const key = j < 10 ? `0${j}` : `${j}`;
          obj[key] =
            key in dbValuesBySite[site_id]
              ? dbValuesBySite[site_id][key]
              : null;
        }
        items.push(obj);
      }
      console.log("items", items);
      this.items = items;
      this.loading = false;
    },
    formatMGArray(arr) {
      return arr.reduce((acc, cur) => {
        const { site_id, var_value_float, var_name } = cur;
        acc[site_id] = { ...acc[site_id], [var_name]: var_value_float };
        return acc;
      }, {});
      /*

      // formatMGArray will transform the array received from endpoint response.data.items

      [
        {
          site_id: 22,
          var_value_float: 1.5,
          var_name: "09",
        },
        {
          site_id: 22,
          var_value_float: 2,
          var_name: "10",
        },
        {
          site_id: 11,
          var_value_float: 1.9,
          var_name: "09",
        },
      ]

      // Into this more usable object dbValuesBySite :

      {
        11: {
          "09": 1.9,
        },
        22: {
          "09": 1.5,
          10: 2,
        },
      }
      */
    },
  },
};
</script>
